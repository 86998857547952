import React from "react";
import Hero from "../images/hero.jpg";

export default function Home() {
    return (
        <section className="hero bg-white">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12 col-lg-5 order-2 order-lg-1  mt-4 mt-lg-0 pt-4 pt-lg-0"
                        data-sal="slide-right"
                        data-sal-delay="200"
                        data-sal-duration="600"
                        data-sal-easing="ease">
                        <div className="hero-content pe-md-0 pe-lg-5">
                            <span className="pre-title text-dark small fw-bold inline-block position-relative text-uppercase">Hi, We're TPM</span>
                            <h1 className="hero-title text-dark mt-3">We Design <br className="d-none d-lg-block" />ideas that work.</h1>
                            <p className="hero-text mt-3 mb-0">We design and create client-oriented softwares and
                    provide application services to operate and maintain client's softwares.</p>
                            <a href="#work" className="btn btn-warning shadow-sm mt-4 fw-bold">Get Started!</a>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-7 order-1 order-lg-2"
                        data-sal="fade"
                        data-sal-delay="200"
                        data-sal-duration="600"
                        data-sal-easing="ease">
                        <div className="position-relative mx-3 mx-md-4 ms-lg-5">
                            <img className="rounded img-fluid w-100 position-relative" src={Hero} alt="Hero" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}