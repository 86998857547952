import React from "react";
import Skills1 from "../images/skill.jpg";
import Skills2 from "../images/skill-1.jpg";
import Skills3 from "../images/skill-2.jpg";

export default function Skills() {
    return (
        <>
            <div id="skills"></div>
            <section className="content-box section section-bg position-relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="skills-img me-0 me-lg-5">
                                <img src={Skills1} className="img-fluid rounded shadow-sm" alt="Skills 1"
                                    data-sal="fade"
                                    data-sal-delay="200"
                                    data-sal-duration="400"
                                    data-sal-easing="ease"/>
                                <div className="mt-3 clearfix">
                                <div className="w-50 float-start pe-2">
                                    <img src={Skills3} className="img-fluid rounded shadow-sm" alt="Skills 3"
                                            data-sal="fade"
                                            data-sal-delay="200"
                                            data-sal-duration="400"
                                            data-sal-easing="ease"/>
                                </div>
                                <div className="w-50 float-end ps-2">
                                    <img src={Skills2} className="img-fluid rounded shadow-sm" alt="Skills 2"
                                            data-sal="fade"
                                            data-sal-delay="200"
                                            data-sal-duration="400"
                                            data-sal-easing="ease" />
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="section-head mt-5 mt-lg-0"
                                data-sal="slide-up"
                                data-sal-delay="200"
                                data-sal-duration="400"
                                data-sal-easing="ease">
                                <span className="pre-title small position-relative fw-bold text-dark text-uppercase">Skills</span>
                                <h2 className="section-title text-dark mt-3">Our Super Powers</h2>
                                <p className="section-subtitle mt-3">Most of our projects are created in a PHP environment. But we also use different modern JS frameworks.</p>
                                <ul className="check-list list-unstyled mb-0">
                                    <li>Custom Web Applications from scratch.</li>
                                    <li>Hybrid Mobile App Development.</li>
                                </ul>
                            </div>
                            <div className="skills-content mt-2 me-0 me-md-5">
                                <div className="row">
                                <div className="col-12">
                                    <div className="mt-4"
                                        data-sal="slide-left"
                                        data-sal-delay="200"
                                        data-sal-duration="500"
                                        data-sal-easing="ease">
                                        <div className="clearfix">
                                            <span className="d-block w-50 float-start fw-bold small">Laravel</span>
                                            <span className="d-block w-50 float-end fw-bold small text-end">90%</span>
                                        </div>
                                        <div className="progress mt-2">
                                            <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '90%' }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                        </div>
                                    </div>
                                    <div className="mt-4"
                                        data-sal="slide-left"
                                        data-sal-delay="200"
                                        data-sal-duration="600"
                                        data-sal-easing="ease">
                                        <div className="clearfix">
                                            <span className="d-block w-50 float-start fw-bold small">CodeIgniter</span>
                                            <span className="d-block w-50 float-end fw-bold small text-end">96%</span>
                                        </div>
                                        <div className="progress mt-2">
                                                <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '96%' }} aria-valuenow="96" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                        </div>
                                    </div>
                                    <div className="mt-4"
                                        data-sal="slide-left"
                                        data-sal-delay="200"
                                        data-sal-duration="700"
                                        data-sal-easing="ease">
                                        <div className="clearfix">
                                            <span className="d-block w-50 float-start fw-bold small">Wordpress</span>
                                            <span className="d-block w-50 float-end fw-bold small text-end">85%</span>
                                        </div>
                                        <div className="progress mt-2">
                                                <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '85%' }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                        </div>
                                    </div>
                                    <div className="mt-4"
                                        data-sal="slide-left"
                                        data-sal-delay="200"
                                        data-sal-duration="800"
                                        data-sal-easing="ease">
                                        <div className="clearfix">
                                            <span className="d-block w-50 float-start fw-bold small">VueJS</span>
                                            <span className="d-block w-50 float-end fw-bold small text-end">90%</span>
                                        </div>
                                        <div className="progress mt-2">
                                                <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '90%' }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                        </div>
                                    </div>
                                    <div className="mt-4"
                                        data-sal="slide-left"
                                        data-sal-delay="200"
                                        data-sal-duration="900"
                                        data-sal-easing="ease">
                                        <div className="clearfix">
                                            <span className="d-block w-50 float-start fw-bold small">Ionic + Capacitor</span>
                                            <span className="d-block w-50 float-end fw-bold small text-end">92%</span>
                                        </div>
                                        <div className="progress mt-2">
                                                <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '92%' }} aria-valuenow="92" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                        </div>
                                    </div>
                                    <div className="mt-4"
                                        data-sal="slide-left"
                                        data-sal-delay="200"
                                        data-sal-duration="1000"
                                        data-sal-easing="ease">
                                        <div className="clearfix">
                                            <span className="d-block w-50 float-start fw-bold small">MySQL</span>
                                            <span className="d-block w-50 float-end fw-bold small text-end">96%</span>
                                        </div>
                                        <div className="progress mt-2">
                                                <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '96%' }} aria-valuenow="96" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}