import React from "react";
import BenjaminKoester from "../images/benjamin-koester.png";
import MarciaLopez from "../images/marcia-lopez.jpg";
import JerlynSilawan from "../images/jerlyn-silawan.jpg";
import { Splide, SplideSlide } from '@splidejs/react-splide';

export default function Testimonials() {
    if (typeof window === "undefined") {
        return (
            <div>
                <div id="testimonials"></div>
            </div>
        );
    }

    const splideOptions = {
        type: 'loop',
        arrows: false,
        pagination: true,
        focus: 0,
        perMove: 1,
        autoplay: true,
        interval: '3000',
        gap: '1rem',
        padding: {
            left: '4rem',
            right: '4rem',
        },
        perPage: 3,
        breakpoints: {
            992: {
                perPage: 2,
                padding: {
                    left: '2rem',
                    right: '2rem',
                },
            },
            640: {
                perPage: 1,
                padding: {
                    left: '1rem',
                    right: '1rem',
                },
            },
        }
    };

    return (
        <div>
            <div id="testimonials"></div>
            <section className="testimonials section bg-dark">
                <div className="container"
                    data-sal="slide-up"
                    data-sal-delay="200"
                    data-sal-duration="400"
                    data-sal-easing="ease">
                    <div className="row">
                        <div className="col">
                            <div className="section-head text-center mx-auto narrow">
                                <span className="pre-title small position-relative fw-bold text-white text-uppercase">Testimonials</span>
                                <h2 className="section-title text-white mt-3">See what our clients are saying</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-2 mt-md-4"
                    data-sal="slide-right"
                    data-sal-delay="200"
                    data-sal-duration="600"
                    data-sal-easing="ease">
                    <div className="row">
                        <div className="col px-0">
                            <Splide options={splideOptions}>
                                <SplideSlide>
                                    <div className="testimonial text-center p-3 p-md-5 rounded shadow-sm">
                                        <img className="testimonial-img rounded-circle" src={BenjaminKoester} alt="Benjamin Koester" />
                                        <h4 className="testimonial-name text-white fs-5 fw-bold mt-3">Benjamin Koester</h4>
                                        <p className="testimonial-text text-muted fs-6 fst-italic">CEO</p>
                                        <p className="testimonial-text text-muted fst-italic mt-2 mb-0">&ldquo; What I love about TPM was the fact that they started to get to know my business, formulate plan and execute the project. It improves workflows and made it easy to access everything in the cloud wherever I go! It's a way in for me to navigate customer growth and community awareness in just fingertips. The team is willing to go extra mile to provide modern designs and concepts that fits for you. Overall, I highly recommend TPM for their professional and systematic approach in fulfilling my needs as a client.&rdquo;</p>
                                    </div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="testimonial text-center p-3 p-md-5 rounded shadow-sm">
                                        <img className="testimonial-img rounded-circle" src={MarciaLopez} alt="Marcia Lopez" />
                                        <h4 className="testimonial-name text-white fs-5 fw-bold mt-3">Marcia Heideliza Lopez</h4>
                                        <p className="testimonial-text text-muted fs-6 fst-italic">Managing Director</p>
                                        <p className="testimonial-text text-muted fst-italic mt-2 mb-0">&ldquo; I am really impressed by the quality of services I received from TPM. They provided an excellent solution and an enormous communication flow throughout the project. They are very efficient and very reliable in a timely manner.&rdquo;</p>
                                    </div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="testimonial text-center p-3 p-md-5 rounded shadow-sm">
                                        <img className="testimonial-img rounded-circle" src={JerlynSilawan} alt="Jerlyn Silawan" />
                                        <h4 className="testimonial-name text-white fs-5 fw-bold mt-3">Jerlyn Silawan</h4>
                                        <p className="testimonial-text text-muted fs-6 fst-italic">Accounting Supervisor</p>
                                        <p className="testimonial-text text-muted fst-italic mt-2 mb-0">&ldquo; TPM is quite an amazing team. Very friendly and makes my work easier. They get it done faster and their works are reliable. Keep up the good work. &rdquo;</p>
                                    </div>
                                </SplideSlide>
                            </Splide>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}