import React from "react";
import DevSvg from "../images/dev.svg";
import BrandingSvg from "../images/branding.svg";
import Danieli from "../images/danieli.png";
import Hotwork from "../images/hotwork.png";
import Hotware from "../images/hotware.png";
import Konijn from "../images/konijn.png";
import Tpm from "../images/tpm.png";
import Durofelguera from "../images/durofelguera.png";
import Irsp from "../images/irsp.png";
import Hula from "../images/HEAT-UP-LATIN-AMERICA.png";
  

export default function Services() {
    return (
        <>
          <section className="section" style={{background: '#f4f4f4'}}>
            <ServicesUpperSection />
          </section>
            <div id="services"></div>
            <section className="services section bg-white">
                <div className="container">
                    
                    <ServicesLowerSection />
                    <div className="row justify-content-between mt-4">
                      <ShowCase 
                        logo={Danieli}
                        companyName="Danieli Corus"
                        techUsed="Laravel / Vue"
                        link="https://danieli.hotwork.ag"
                      />
                      <ShowCase 
                        logo={Hotwork}
                        companyName="Hotwork"
                        techUsed="Wordpress"
                        link="https://hotwork.ag"
                      />
                      <ShowCase
                        logo={Konijn}
                        companyName="Konijn"
                        techUsed="Laravel / Vue"
                        link="http://konijn.tpm-apps.com"
                      />
                      <ShowCase 
                        logo={Hotware}
                        companyName="Hotware"
                        techUsed="Codeigniter / Javascript"
                        link="https://app.hotwork.ag"
                      />
                      <ShowCase
                        logo={Tpm}
                        companyName="TPM"
                        techUsed="Codeigniter / Javascript"
                        link="http://appware.tpm-apps.com" 
                      />
                      <ShowCase
                        logo={Durofelguera}
                        companyName="Durofelguera Demo"
                        techUsed="Codeigniter / Javascript"
                        link="http://durofelguera.tpm-apps.com"
                      />
                      <ShowCase
                        logo={Hotware}
                        companyName="Certificate Generator"
                        techUsed="Codeigniter / Javascript"
                        link="http://maintenance.hotwork.ag"
                      />
                      <ShowCase 
                        logo={Irsp}
                        companyName="IRSP"
                        techUsed="Codeigniter / Javascript"
                        link="http://irsp.hotwork.ag"
                      />
                    <ShowCase 
                        logo={Hula}
                        companyName="Heat Up Latin America"
                        techUsed="Codeigniter / Javascript"
                        link="https://www.heat-up.mx"
                      />
                    </div>
                </div>
            </section>
        </>
    );
}

const ShowCase = ({ logo, companyName, techUsed, link }) => {
  return (
    <div className="col-md-3"
      data-sal="zoom-in"
      data-sal-delay="200"
      data-sal-duration="500"
      data-sal-easing="ease" style={{textAlign:'center'}}
    >
      <div className="showcase-container">
        <a href={link}>
          <img src={logo} style={{objectFit:'contain'}}/>
        </a>
        <h4 className="text-dark">{companyName}</h4>
        {/* <p className="text-dark mb-0">{techUsed}</p> */}
      </div>
    </div>
  )
}

const ServicesUpperSection = () => {
  return (
    <>

      <div className="row">
        <div className="col">
            <div className="section-head text-center mx-auto narrow"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="500"
                data-sal-easing="ease">
                <span className="pre-title small position-relative fw-bold text-dark text-uppercase">Services</span>
                <h2 className="section-title text-dark mt-3">What We Do</h2>
                <p className="section-subtitle mt-3 mb-0">We develop custom web and mobile applications for companies as per their business needs. We also create stunning and responsive landing pages.  </p>
            </div>
        </div>
    </div>
    <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-3"
            data-sal="slide-right"
            data-sal-delay="400"
            data-sal-duration="600"
            data-sal-easing="ease">
            <div className="service-box text-center mt-5">
                <img className="service-box-icon" src={DevSvg} alt="Web Development" />
                <h3 className="service-box-title text-dark mt-4 text-uppercase">Web Development</h3>
                <p className="service-box-text mt-3 mb-0">Custom Web Applications and Websites.</p>
            </div>
        </div>
        <div className="col-12 col-md-6 col-lg-3"
            data-sal="slide-left"
            data-sal-delay="400"
            data-sal-duration="600"
            data-sal-easing="ease">
            <div className="service-box text-center mt-5">
                <img className="service-box-icon" src={BrandingSvg} alt="Mobile Development" />
                <h3 className="service-box-title text-dark mt-4 text-uppercase">Mobile Development</h3>
                <p className="service-box-text mt-3 mb-0">Custom Mobile Applications for Android and IOS.</p>
            </div>
        </div>
    </div>

    </>
  )
}

const ServicesLowerSection = () => {
  return(
    <>
      <div className="row mt-5"
      data-sal="slide-right"
      data-sal-delay="400"
      data-sal-duration="600"
      data-sal-easing="ease">
        <div className="col mt-3">
            <div className="section-head text-center mx-auto narrow">
                <span className="pre-title small position-relative fw-bold text-dark text-uppercase">Work</span>
                <h3 className="section-title text-dark mt-2">Browse our functional system</h3>
                <p className="section-subtitle mt-3 mb-0">For the past couple of years, these are the projects we've built for our amazing clients. </p>
            </div>
        </div>
      </div>
    </>
  )
}