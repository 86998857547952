import React from "react";
import TeamWarren from "../images/team-warren.jpeg";
import TeamMervin from "../images/team-mervin.jpg";
import TeamJay from "../images/team-jay.jpg";
import TeamJoshua from "../images/team-joshua.jpeg";

export default function Team() {
    return (
        <div>
            <div id="team"></div>
            <section className="team section section-bg">
                <div className="container">
                    <div className="row"
                        data-sal="slide-up"
                        data-sal-delay="200"
                        data-sal-duration="400"
                        data-sal-easing="ease">
                        <div className="col">
                            <div className="section-head text-center mx-auto narrow">
                                <span className="pre-title small position-relative fw-bold text-dark text-uppercase">Team</span>
                                <h2 className="section-title text-dark mt-3">Meet Our Core Team Members</h2>
                                <p className="section-subtitle mt-3 mb-0">This is our team who work hard to create amazing projects. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-3 justify-content-center">
                        <div className="col-6 col-md-6 col-lg-3"
                            data-sal="slide-right"
                            data-sal-delay="200"
                            data-sal-duration="600"
                            data-sal-easing="ease">
                            <div className="team-member mt-3 mt-md-5">
                                <div className="card border-0 shadow-sm">
                                    <img src={TeamWarren} className="card-img-top rounded" alt="Warren" />
                                    <div className="card-body py-4 text-center">
                                        <h5 className="card-title fs-6 fw-bold text-uppercase">John Warren Tañiza</h5>
                                        <h6 className="card-subtitle mb-0 text-muted small">Project Manager</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-3"
                            data-sal="slide-right"
                            data-sal-delay="200"
                            data-sal-duration="600"
                            data-sal-easing="ease">
                            <div className="team-member mt-3 mt-md-5">
                                <div className="card border-0 shadow-sm">
                                    <img src={TeamMervin} className="card-img-top rounded" alt="Mervin" />
                                    <div className="card-body py-4 text-center">
                                        <h5 className="card-title fs-6 fw-bold text-uppercase">Mervin Villaceran</h5>
                                        <h6 className="card-subtitle mb-0 text-muted small">Team Lead</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-3"
                            data-sal="slide-left"
                            data-sal-delay="200"
                            data-sal-duration="600"
                            data-sal-easing="ease">
                            <div className="team-member mt-3 mt-md-5">
                                <div className="card border-0 shadow-sm">
                                    <img src={TeamJay} className="card-img-top rounded" alt="Jay" />
                                    <div className="card-body py-4 text-center">
                                        <h5 className="card-title fs-6 fw-bold text-uppercase">Joemy Jay Flores</h5>
                                        <h6 className="card-subtitle mb-0 text-muted small">Fullstack Developer</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-3"
                            data-sal="slide-left"
                            data-sal-delay="200"
                            data-sal-duration="600"
                            data-sal-easing="ease">
                            <div className="team-member mt-3 mt-md-5">
                                <div className="card border-0 shadow-sm">
                                    <img src={TeamJoshua} className="card-img-top rounded" alt="Joshua" />
                                    <div className="card-body py-4 text-center">
                                        <h5 className="card-title fs-6 fw-bold text-uppercase">Joshua Pongasi</h5>
                                        <h6 className="card-subtitle mb-0 text-muted small">Fullstack Developer</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}