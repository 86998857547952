import React from "react";
import YearsSvg from "../images/years.svg";
import ClientsSvg from "../images/clients.svg";
import ProjectsSvg from "../images/projects.svg";
import RevenueSvg from "../images/revenue.svg";

export default function Stats() {
    return (
        <div>
            <div id="stats"></div>
            <section className="stats section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6"
                            data-sal="slide-right"
                            data-sal-delay="200"
                            data-sal-duration="600"
                            data-sal-easing="ease">
                            <div className="stats-box-text">
                                <span className="pre-title small position-relative fw-bold text-dark text-uppercase">Stats</span>
                                <h2 className="stats-box-title section-title text-dark mt-3 mb-4">Amazing Numbers Speaks Our Truth</h2>
                                <p>We are in the industry for more than 3 years now and we will continue to build web and mobile applications for our clients.</p>
                                <p>We've built more than 10 web applications and 2 mobile applications that helped our clients on their businesses.</p>
                                <p>We are here to help them grow their businesses and create exact solutions that they are looking for.</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="stats-wrap text-center ps-0 ps-lg-5">
                                <div className="row gx-3">
                                    <div className="col-6 col-lg-6"
                                        data-sal="slide-up"
                                        data-sal-delay="300"
                                        data-sal-duration="600"
                                        data-sal-easing="ease">
                                        <div className="stats-box bg-white mt-3 px-4 py-4 py-md-5">
                                            <img className="stats-icon" src={YearsSvg} alt="Years" />
                                            <h4 className="stats-title mt-3">3 years</h4>
                                            <p className="stats-text text-muted mb-0 small">in Business</p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-6"
                                        data-sal="slide-up"
                                        data-sal-delay="300"
                                        data-sal-duration="700"
                                        data-sal-easing="ease">
                                        <div className="stats-box bg-white mt-3 px-4 py-4 py-md-5">
                                            <img className="stats-icon" src={ClientsSvg} alt="Client" />
                                            <h4 className="stats-title mt-3">5+</h4>
                                            <p className="stats-text text-muted mb-0 small">Clients</p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-6"
                                        data-sal="slide-up"
                                        data-sal-delay="300"
                                        data-sal-duration="800"
                                        data-sal-easing="ease">
                                        <div className="stats-box bg-white mt-3 px-4 py-4 py-md-5">
                                            <img className="stats-icon" src={ProjectsSvg} alt="Projects" />
                                            <h4 className="stats-title mt-3">10+</h4>
                                            <p className="stats-text text-muted mb-0 small">Projects</p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-6"
                                        data-sal="slide-up"
                                        data-sal-delay="300"
                                        data-sal-duration="900"
                                        data-sal-easing="ease">
                                        <div className="stats-box bg-white mt-3 px-4 py-4 py-md-5">
                                            <img className="stats-icon" src={RevenueSvg} alt="Revenue" />
                                            <h4 className="stats-title mt-3">1M</h4>
                                            <p className="stats-text mb-0 small">Revenue</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}