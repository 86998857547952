import * as React from "react";
import Page from "../components/layouts/page";
import Home from "../components/home";
import Work from "../components/work";
import Services from "../components/services";
import Skills from "../components/skills";
import Testimonials from "../components/testimonials";
import Team from "../components/team";
import Stats from "../components/stats";

// markup
const IndexPage = () => {
    return (
        <Page>
            <Home />
            <Services />
            <Skills />
            <Testimonials />
            <Team />
            <Work />
            <Stats />
        </Page>
    )
}

export default IndexPage
