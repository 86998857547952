import React from "react";
import { Link } from "gatsby";
import InventorySystem from "../images/inventory-system.png";
import CompanyWebsite from "../images/company-website.png";
import DanieliMobileApp from "../images/danieli-mobile-app.png";
import DocumentSystem from "../images/documents-system.png";
import CompanyMobileApp from "../images/company-mobile-app.png";
import AccountingSystem from "../images/accounting-system.jpeg";
import POSystem from "../images/po-system.png";
import ExportDocument from "../images/export-document.jpeg";


export default function Work() {
    return (
        <>
            <div id="work"></div>
            <section className="section bg-white">
                <div className="container"> 
                    <div className="row"
                        data-sal="slide-right"
                        data-sal-delay="400"
                        data-sal-duration="600"
                        data-sal-easing="ease">
                        <div className="col">
                            <div className="section-head text-center mx-auto narrow">
                                <h2 className="section-title text-dark mt-4">Gallery</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 px-0"
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-duration="500"
                            data-sal-easing="ease">
                            <Link to="/projects/inventory-system">
                                <div className="portfolio-item position-relative overflow-hidden yellow-border">
                                    <img className="portfolio-item-img img-fluid w-100" src={InventorySystem} alt="Inventory System" />
                                    <div className="portfolio-item-content position-absolute rounded text-center">
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <h3 className="project-title text-white">Inventory System</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 px-0"
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-duration="500"
                            data-sal-easing="ease">
                            <Link to="/projects/company-website">
                                <div className="portfolio-item position-relative overflow-hidden black-border">
                                    <img className="portfolio-item-img img-fluid w-100" src={CompanyWebsite} alt="Company Website" />
                                    <div className="portfolio-item-content position-absolute rounded text-center">
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <h3 className="project-title text-white">Company Website</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 px-0"
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-duration="500"
                            data-sal-easing="ease">
                            <Link to="/projects/project-management-mobile-app">
                                <div className="portfolio-item position-relative overflow-hidden yellow-border">
                                    <img className="portfolio-item-img img-fluid w-100" src={DanieliMobileApp} alt="Danieli Mobile App" />
                                    <div className="portfolio-item-content position-absolute rounded text-center">
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <h3 className="project-title text-white">Project Management Mobile App</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 px-0"
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-duration="500"
                            data-sal-easing="ease">
                            <Link to="/projects/document-management-system">
                                <div className="portfolio-item position-relative overflow-hidden black-border">
                                    <img className="portfolio-item-img img-fluid w-100" src={DocumentSystem} alt="Document System" />
                                    <div className="portfolio-item-content position-absolute rounded text-center">
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <h3 className="project-title text-white">Document Management System</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 px-0"
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-duration="500"
                            data-sal-easing="ease">
                            <Link to="/projects/company-mobile-app">
                                <div className="portfolio-item position-relative overflow-hidden black-border">
                                    <img className="portfolio-item-img img-fluid w-100" src={CompanyMobileApp} alt="Company Mobile App" />
                                    <div className="portfolio-item-content position-absolute rounded text-center">
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <h3 className="project-title text-white">Company Mobile App</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 px-0"
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-duration="500"
                            data-sal-easing="ease">
                            <Link to="/projects/accounting-system">
                                <div className="portfolio-item position-relative overflow-hidden yellow-border">
                                    <img className="portfolio-item-img img-fluid w-100" src={AccountingSystem} alt="Accounting System" />
                                    <div className="portfolio-item-content position-absolute rounded text-center">
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <h3 className="project-title text-white">Accounting System</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 px-0"
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-duration="500"
                            data-sal-easing="ease">
                            <Link to="/projects/purchase-order-management-system">
                                <div className="portfolio-item position-relative overflow-hidden black-border">
                                    <img className="portfolio-item-img img-fluid w-100" src={POSystem} alt="Purchase Order System" />
                                    <div className="portfolio-item-content position-absolute rounded text-center">
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <h3 className="project-title text-white">Purchase Order Management System</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 px-0"
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-duration="500"
                            data-sal-easing="ease">
                            <Link to="/projects/export-document-system">
                                <div className="portfolio-item position-relative overflow-hidden yellow-border">
                                    <img className="portfolio-item-img img-fluid w-100" src={ExportDocument} alt="Export Document System" />
                                    <div className="portfolio-item-content position-absolute rounded text-center">
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <h3 className="project-title text-white">Export Document System</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}